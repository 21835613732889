import { MDBBadge, MDBBtn, MDBCard, MDBCardBody, MDBCardHeader, MDBDropdown, MDBDropdownItem, MDBDropdownMenu, MDBDropdownToggle, MDBIcon, MDBInput, MDBInputGroup, MDBTable, MDBTableBody, MDBTableHead, MDBValidation } from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IDetails, IReservationsList } from "../../global/admin/Interfaces";
import Constants from "../../utilities/AdminConstants";
import axios, { AxiosResponse } from "axios";
import { niceDate } from "../../global/Functions";
import InfoModal from "../../components/adminView/InfoModal";

export default function Rezerwacje() {
    const navigate = useNavigate();

    useEffect(() => {
        if (!localStorage.getItem('user')?.length) {
            navigate("/admin/login");
            window.location.reload();
        }
        fetchData();
    }, []);

    const [searchData, setSearchData] = useState<IReservationsList[]>([]);
    const [searchPhase, setSearchPhase] = useState<string>("");
    const [filters, setFilters] = useState<number>(0);

    const [infoModal, setInfoModal] = useState<IDetails>({
        Id: "",
        PatientId: "",
        BookerName: "",
        BookerSurname: "",
        Name: "",
        Surname: "",
        Email: "",
        Phone: "",
        Date: "",
        Time: "",
        Duration: 30,
        IsBookingMyself: 0,
        IsUserBooked: 0,
        IsConfirmed: 0,
        IsCanceled: 0,
        DateCreated: new Date(),
        DateConfirmed: new Date(),
        DateCanceled: new Date(),
    });
    const [infoModalStatus, setInfoModalStatus] = useState<boolean>(false);

    useEffect(() => {
        fetchData();
    }, [searchPhase]);

    const fetchData = async () => {
        const url = Constants.API_URL_GET_RESERVATION;
        axios
            .get<IReservationsList[]>(url + `?query=${searchPhase.length > 2 ? searchPhase.toString() : ""}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('user')}`,
                }
            })
            .then((response: AxiosResponse) => {
                setSearchData(response.data);
            })
            .catch(function (error: any) {
                console.log(error);
            });
    }

    const ReservationStatus = (confirmed: number, canceled: number) => {
        if (confirmed && !canceled) {
            return (
                <h6>
                    <MDBBadge color='success'>
                        Potwierdzona
                    </MDBBadge>
                </h6>
            );
        } else if (!confirmed && !canceled) {
            return (
                <h6>
                    <MDBBadge color='primary'>
                        Złożona
                    </MDBBadge>
                </h6>
            );
        }
        return (
            <h6>
                <MDBBadge color='danger'>
                    Anulowana
                </MDBBadge>
            </h6>
        );
    }

    const displayInfo = (item: IReservationsList) => {
        setInfoModal({
            Id: item.id.toString(),
            PatientId: item.patientId.toString(),
            BookerName: item.bookerName,
            BookerSurname: item.bookerSurname,
            Name: item.name,
            Surname: item.surname,
            Email: item.email,
            Phone: item.phone,
            Date: item.date.toString(),
            Time: item.time,
            Duration: item.duration,
            IsBookingMyself: item.isBookingMyself,
            IsUserBooked: item.isUserBooked,
            IsConfirmed: item.isConfirmed,
            IsCanceled: item.isCanceled,
            DateCreated: item.dateCreated,
            DateConfirmed: item.dateConfirmed,
            DateCanceled: item.dateCanceled,
        });
        setInfoModalStatus(true);
    }

    const ReservationTable = () => {
        const result: any = [];
        const partPhase = searchPhase.split(' ');

        if (partPhase.length) {
            searchData.reverse().sort(function (first, second) {
                if (first.bookerSurname.toLowerCase().localeCompare(partPhase[1])) {
                    return 1;
                } else if (second.bookerSurname.toLowerCase().localeCompare(partPhase[1])) {
                    return -1;
                }
                return 0;
            })?.forEach((item, key) => {
                if ((filters == 0 && !item.isCanceled) ||
                    (filters == 1) ||
                    (filters == 2 && item.isConfirmed && !item.isCanceled) ||
                    (filters == 3 && !item.isConfirmed && !item.isCanceled) ||
                    (filters == 4 && item.isCanceled)) {
                    result.push(
                        <tr className="dataTD" key={key}>
                            <td className={item.isUserBooked ? `userReservation` : ``}>
                                <p className='fw-bold mb-1'>{item.name} {item.surname}</p>
                                <p className='text-muted mb-0'>{item.phone.substring(0, 3) + ` ` + item.phone.substring(3, 6) + ` ` + item.phone.substring(6)}</p>
                            </td>
                            <td>
                                <p className='fw-normal mb-1'>{niceDate(item.date.toString())}</p>
                                <p className='text-muted mb-0'>
                                    {parseInt(item.time.slice(0, 2)) + ':' + item.time.slice(3, 5) + ' '}
                                    do {parseInt(item.time.slice(0, 2)) + (item.duration == 90 ? 2 : 1) + ':' + item.time.slice(3, 5)}
                                </p>
                            </td>
                            <td style={{ textAlign: "center" }}>
                                {ReservationStatus(item.isConfirmed, item.isCanceled)}
                            </td>
                            <td style={{ textAlign: "center" }}>
                                <MDBBtn color='link' rounded size='sm' onClick={() => { displayInfo(item) }}>
                                    Info
                                </MDBBtn>
                            </td>
                        </tr>
                    )
                }
            });
        } else {
            searchData.sort((a, b) => {
                return new Date(b.dateCreated).getTime() - new Date(a.dateCreated).getTime();
            });

            searchData.reverse().forEach((item, key) => {
                if ((filters == 0 && !item.isCanceled) ||
                    (filters == 1) ||
                    (filters == 2 && item.isConfirmed && !item.isCanceled) ||
                    (filters == 3 && !item.isConfirmed && !item.isCanceled) ||
                    (filters == 4 && item.isCanceled)) {
                    result.push(
                        <tr className="dataTD" key={key}>
                            <td className={item.isUserBooked ? `userReservation` : ``}>
                                <p className='fw-bold mb-1'>{item.name} {item.surname}</p>
                                <p className='text-muted mb-0'>{item.phone.substring(0, 3) + ` ` + item.phone.substring(3, 6) + ` ` + item.phone.substring(6)}</p>
                            </td>
                            <td>
                                <p className='fw-normal mb-1'>{niceDate(item.date.toString())}</p>
                                <p className='text-muted mb-0'>
                                    {parseInt(item.time.slice(0, 2)) + ':' + item.time.slice(3, 5) + ' '}
                                    do {parseInt(item.time.slice(0, 2)) + (item.duration == 90 ? 2 : 1) + ':' + item.time.slice(3, 5)}
                                </p>
                            </td>
                            <td style={{ textAlign: "center" }}>
                                {ReservationStatus(item.isConfirmed, item.isCanceled)}
                            </td>
                            <td style={{ textAlign: "center" }}>
                                <MDBBtn color='link' rounded size='sm' onClick={() => { displayInfo(item) }}>
                                    Info
                                </MDBBtn>
                            </td>
                        </tr>
                    )
                }
            });
        }

        if (result.length) {
            return (
                <>{result}</>
            );
        }
        return (
            <tr className="dataTD">
                <td colSpan={4} style={{ textAlign: "center", fontWeight: "bold" }}>
                    Brak rezerwacji
                </td>
            </tr>
        );
    }

    return (
        <>
            <InfoModal
                isActive={infoModalStatus}
                handleModal={() => setInfoModalStatus(false)}
                forceUpdate={fetchData}
                data={infoModal}
            />

            <MDBCard>
                <MDBCardHeader style={{ textAlign: "center" }}>
                    <div className="row">
                        <div className="col-12">Baza danych</div>
                    </div>
                </MDBCardHeader>
                <div className="dataSearchBar mx-auto">
                    <MDBInputGroup>
                        <MDBDropdown>
                            <MDBDropdownToggle color="info" style={{ borderRadius: "25px 0 0 25px", lineHeight: "1.5" }}>Filtry</MDBDropdownToggle>
                            <MDBDropdownMenu>
                                <MDBDropdownItem link onClick={() => setFilters(2)}>Potwierdzone</MDBDropdownItem>
                                <MDBDropdownItem link onClick={() => setFilters(3)}>Niepotwierdzone</MDBDropdownItem>
                                <MDBDropdownItem link onClick={() => setFilters(4)}>Anulowane</MDBDropdownItem>
                                <MDBDropdownItem divider />
                                <MDBDropdownItem link onClick={() => setFilters(1)}>Wszystkie</MDBDropdownItem>
                                <MDBDropdownItem link onClick={() => setFilters(0)}>Domyślne</MDBDropdownItem>
                            </MDBDropdownMenu>
                        </MDBDropdown>
                        <MDBInput
                            className="searchPhaseBar"
                            value={searchPhase}
                            type='text'
                            onChange={(e) => setSearchPhase(e.target.value)}
                            label='Wyszukaj pacjenta..'
                        />
                        <MDBBtn
                            outline
                            rounded
                            color="warning"
                            onClick={() => {
                                setSearchPhase("");
                                setFilters(0);
                                fetchData();
                            }}
                        ><MDBIcon fas size="lg" icon="backspace" /></MDBBtn>
                    </MDBInputGroup>
                </div>
                <MDBCardBody style={{ maxHeight: "700px", overflow: "scroll", overflowX: "hidden" }}>
                    <MDBTable align='middle' responsive>
                        <MDBTableHead>
                            <tr style={{ fontWeight: "800" }}>
                                <th scope='col'>Pacjent</th>
                                <th scope='col'>Termin wizyty</th>
                                <th scope='col' style={{ textAlign: "center" }}>Status</th>
                                <th scope='col' style={{ textAlign: "center" }}>Opcje</th>
                            </tr>
                        </MDBTableHead>
                        <MDBTableBody>
                            <ReservationTable />
                        </MDBTableBody>
                    </MDBTable>
                </MDBCardBody >
            </MDBCard >
        </>
    );
}
