import { MDBCard, MDBCardHeader, MDBCardBody } from "mdb-react-ui-kit";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import ReactGa from 'react-ga';

export default function Index() {    
    return (
        <>
            <Helmet>
                <title>Homeopatyczny Gabinet Lekarski w Siedlcach</title>
                <meta name="title" content="Homeopatyczny Gabinet Lekarski w Siedlcach" />
                <meta name="description" content="Nazywam się Małgorzata Grabowiec, jestem lekarzem medycyny i prowadzę Homeopatyczny Gabinet Lekarski w Siedlcach. Umów się na wizytę do lekarza online." />
                <meta name="keywords" content="homeopatia,lekarz,doktor,siedlce,wizyta,medycyna,grabowiec,gabinet,rodzinny,homeopata" />
                <meta http-equiv="Content-Type" content="text/html;charset=UTF-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>
            <MDBCard>
                <MDBCardHeader style={{ fontStyle: "italic" }}>Homeopatyczny Gabinet Lekarski Małgorzata Grabowiec</MDBCardHeader>
                <MDBCardBody>
                    <div className="row">
                        <div className="col-md-6">
                            <p className="sectionTitle">O mnie</p>
                            <p>
                                Nazywam się Małgorzata Grabowiec. Jestem lekarzem medycyny.
                                Od czasów studiów interesowałam się medycyną alternatywną, fitoterapią,
                                irydologią oraz ziołolecznictwem.
                            </p>
                            <p>
                                Zafascynowała i zaintrygowała mnie
                                otrzymana w prezencie książka Jerzego Łozowskiego „Zarys homeopatii”.
                                Ojciec polskiej homeopatii opisywał w niej historię powstania oraz
                                osiągnięcia twórcy homeopatii Samuela Hahnemanna.
                            </p>
                            <p>
                                Rozpoczęłam samodzielne
                                studia, pogłębione na kursach organizowanych przez francuską firmę Boiron
                                oraz Wielkopolskie Towarzystwo Lekarzy. Wiedzę tę pogłębiam oraz stosuję
                                w prywatnej praktyce lekarskiej cały czas od ponad 20 lat.
                            </p>
                        </div>
                        <div className="col-md-6 d-flex justify-content-center">
                            <img src={require("../assets/images/sample/granulki-homeopatyczne.jpg")} className="sectionImg" alt="Leczenie metoda homeopatyczna w Siedlcach" />
                        </div>
                    </div>
                    <div className="row mb-5">
                        <div className="col-12 button_main">
                            <a href="rezerwacja-wizyty">
                                <button className="btn btn-outline" type="button">Rezerwacja wizyty</button>
                            </a>
                        </div>
                    </div>
                    <div className="row mb-5">
                        <div className="col-md-6 d-flex justify-content-center">
                            <img src={require("../assets/images/sample/krople-lecznicze.jpg")} className="sectionImg" alt="Homeopata Siedlce. Lekarz Siedlce." />
                        </div>
                        <div className="col-md-6">
                            <p className="sectionTitle"> Leczenie homeopatyczne</p>
                            <p> Można stosować w zaburzeniach czynnościowych układu nerwowego i wewnątrzwydzielniczego.
                                Leczeniu zaburzeń odporności, nawracających przeziębieniach, chorobach alergicznych
                                Leczeniu przewlekłych procesów chorobowych w ich początkowych okresach
                                W przygotowaniu do zabiegów operacyjnych, okresach pooperacyjnych, urazach czy ranach
                            </p>
                        </div>
                    </div>
                    <div className="row mb-5">
                        <div className="col-md-6">
                            <p className="sectionTitle">Profilaktyka homeopatyczna obejmuje</p>
                            <ul>
                                <li>Opiekę nad ciężarną. </li>
                                <li>Przygotowanie do porodu i zapobieganie powikłaniom po nim. </li>
                                <li>Opiekę nad noworodkiem i niemowlęciem w czasie karmienia piersią. </li>
                                <li>Profilaktyka wad postawy, próchnicy zębów, okresu dojrzewania. </li>
                                <li>Podnoszenie odporności i wydolności ogólnej. </li>
                                <li>Wspomaganie pacjenta w różnych trudnych okresach jego życia. </li>
                                <li>Schyłkowe okresy życia człowieka. </li>
                            </ul>
                        </div>
                        <div className="col-md-6 d-flex justify-content-center">
                            <img src={require("../assets/images/sample/rozlinne-leki-homeopatyczne.jpg")} className="sectionImg" alt="Leczenie medycyna alternatywna." />
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-md-6 d-flex justify-content-center">
                            <img src={require("../assets/images/sample/leczenie-holistyczne.jpg")} className="sectionImg" alt="Leczenie holistyczne w Siedlcach. Lekarz Siedlce." />
                        </div>
                        <div className="col-md-6">
                            <p className="sectionTitle">Strony homeopatyczne</p>
                            <ul className="sectionLinks">
                                <li><a target="_blank" href="http://www.lekarzehomeopaci.pl/nauka/publikacje/109-historia-homeopatii-w-watykanie-dr-n-med-jacek-norkowski-op">
                                    Lekarze Homeopaci - dr. n.med Jacek Norkowski</a></li>
                                <li><a target="_blank" href="https://homeopathyeurope.org/">
                                    Europejska Homeopatyczna Społeczność</a></li>
                                <li><a target="_blank" href="http://homeopatia-pth.pl/">
                                    PTH - Polskie Towarzystwo Homeopatyczne</a></li>
                                <li><a target="_blank" href="http://www.lekarzehomeopaci.pl/linki/8-homeopatia/246-lek-homeopatyczny-to-nie-placebo-lecz-biofizyczna-metoda-przywracania-zdrowia">
                                    Naukowy opis działania leków homeopatycznych</a></li>
                                <li><a target="_blank" href="https://www.lehning.pl">
                                    Lehning - Homeopatyczne badania kliniczne</a></li>
                                <li><a target="_blank" href="https://www.boiron.pl/nasza-wizja/poznaj-homeopatie">
                                    Boiron - Czym jest homeopatia?</a></li>
                            </ul>
                        </div>
                    </div>
                </MDBCardBody>
            </MDBCard>
        </>
    );
}
