import axios, { AxiosResponse } from "axios";
import { MDBBtn, MDBModal, MDBModalDialog, MDBModalContent, MDBModalHeader, MDBModalTitle, MDBModalBody, MDBModalFooter, MDBListGroup, MDBListGroupItem, MDBTable, MDBTableBody, MDBTableHead } from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import { IModalInfoProps, IPatientCard } from "../../global/admin/Interfaces";
import Constants from "../../utilities/AdminConstants";
import { getCurrentTime, niceDate, niceDateTime } from "../../global/Functions";

export default function InfoModal(props: IModalInfoProps) {
    let daysOfWeek = ['', 'Poniedziałek', 'Wtorek', 'Środa', 'Czwartek', 'Piątek', 'Sobota', 'Niedziela'];
    let monthsNames = ['Stycznia', 'Lutego', 'Marca', 'Kwietnia', 'Maja', 'Czerwca', 'Lipca', 'Sierpnia', 'Września', 'Października', 'Listopada', 'Grudnia'];

    const [patientCard, setPatientCard] = useState<IPatientCard>();
    const [accountStatus, setAccountStatus] = useState<boolean>(false);

    useEffect(() => {
        if (props.data.PatientId.length) {
            axios
                .get<IPatientCard[]>(Constants.API_URL_GET_PATIENT_CARD + `?Id=${props.data.PatientId}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('user')}`,
                    }
                })
                .then((response: AxiosResponse) => {
                    setPatientCard(response.data);
                    setAccountStatus(response.data.patient.isBlocked ? true : false);
                })
                .catch(function (error: any) {
                    console.log(error);
                });
        }
    }, [props.isActive]);

    const updateBlock = (status: boolean) => {
        if (props.data.PatientId.length) {
            setAccountStatus(status);
            axios
                .get(Constants.API_URL_GET_BLOCK + `?Id=${props.data.PatientId}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('user')}`,
                    }
                })
                .catch(function (error: any) {
                    console.log(error);
                });
        }
    }

    const cancelReservation = () => {
        axios
            .get(Constants.API_URL_GET_CANCEL + `?Id=${props.data.Id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('user')}`,
                }
            })
            .then((response: AxiosResponse) => {
                props.forceUpdate(100 + Math.random() * (999999));
                props.handleModal();
            })
            .catch(function (error: any) {
                console.log(error);
            });

    }

    const confirmReservation = () => {
        axios
            .get(Constants.API_URL_GET_CONFIRM + `?Id=${props.data.Id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('user')}`,
                }
            })
            .then((response: AxiosResponse) => {
                props.forceUpdate(100 + Math.random() * (999999));
                props.handleModal();
            })
            .catch(function (error: any) {
                console.log(error);
            });
    }

    const ReservationTable = () => {
        const result: any = [];

        patientCard?.reservationsList.forEach((item, key) => {
            if (item.isConfirmed && !item.isCanceled) {
                result.push(
                    <tr key={key}>
                        <td scope='row'>{item.duration == 30 ? `Kolejna wizyta` : `Pierwsza wizyta`}</td>
                        <td>{item.time}</td>
                        <td>{niceDate(item.date.toString())}</td>
                    </tr>
                )
            }
        });
        if (!result.length) {
            result.push(
                <tr key={0}>
                    <td scope='row' colSpan={3} style={{ textAlign: "center", fontWeight: "bold" }}>Brak odbytych rezerwacji</td>
                </tr>
            )
        }

        return (
            <MDBTable small>
                <MDBTableHead>
                    <tr>
                        <th scope='col'>Typ</th>
                        <th scope='col'>Godzina</th>
                        <th scope='col'>Data</th>
                    </tr>
                </MDBTableHead>
                <MDBTableBody>
                    {result}
                </MDBTableBody>
            </MDBTable>
        );
    }

    let selectedDate = new Date(props.data.Date);
    return (
        <>
            <MDBModal
                id="infoModal"
                show={props.isActive}
                onHide={() => props.handleModal()}
                staticBackdrop={true}
                tabIndex='-1'>
                <MDBModalDialog>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>
                                {props.data.Duration != 30 ? `Pierwsza wizyta | ` : `Kolejna wizyta | `}
                                {daysOfWeek[selectedDate.getDay()] + ` ` + selectedDate.getDate()}
                                {` ` + monthsNames[selectedDate.getMonth()] + ` ` + selectedDate.getFullYear()}
                                {` | ` + parseInt(props.data.Time.slice(0, 2)) + ':' + props.data.Time.slice(3, 5) + ' '}
                            </MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={() => props.handleModal()}></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody>
                            <div className="row">
                                <div className="col-6">
                                    <MDBListGroup light>
                                        <MDBListGroupItem className='d-flex justify-content-between align-items-start'>
                                            <div className='ms-2 me-auto'>
                                                <div className='fw-bold'>Dane pacjenta</div>
                                                {props.data.Name + ` ` + props.data.Surname}
                                                <br />{props.data.IsUserBooked ? `Rezerwacja pacjenta` : `Rezerwacja lekarza`}
                                            </div>
                                        </MDBListGroupItem>
                                    </MDBListGroup>
                                </div>
                                <div className="col-6">
                                    <MDBListGroup light>
                                        <MDBListGroupItem className='d-flex justify-content-between align-items-start'>
                                            <div className='ms-2 me-auto'>
                                                <div className='fw-bold'>Dane kontaktowe</div>
                                                {props.data.Phone.substring(0, 3) + ` ` + props.data.Phone.substring(3, 6) + ` ` + props.data.Phone.substring(6)}
                                                <br />{props.data.Email}
                                            </div>
                                        </MDBListGroupItem>
                                    </MDBListGroup>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <MDBListGroup light>
                                        <MDBListGroupItem className='d-flex justify-content-between align-items-start'>
                                            <div className='ms-2 me-auto'>
                                                <div className='fw-bold'>Data złożenia</div>
                                                {niceDateTime(props.data.DateCreated.toString())}
                                            </div>
                                        </MDBListGroupItem>
                                    </MDBListGroup>
                                </div>
                                <div className="col-6">
                                    <MDBListGroup light>
                                        <MDBListGroupItem className='d-flex justify-content-between align-items-start'>
                                            <div className='ms-2 me-auto'>
                                                <div className='fw-bold'>{props.data.IsCanceled ? 'Data anulowania' : 'Data potwierdzenia'}</div>
                                                {props.data.IsCanceled ? niceDateTime(props.data.DateCanceled.toString()) : props.data.DateConfirmed ? niceDateTime(props.data.DateConfirmed.toString()) : `Niepotwierdzona`}
                                            </div>
                                        </MDBListGroupItem>
                                    </MDBListGroup>
                                </div>
                            </div>
                            <hr />
                            <div className="row" style={{ textAlign: "center" }}>
                                <div className="col-4"><strong>{patientCard?.reservationsList.filter(x => x.isConfirmed && !x.isCanceled && (new Date(x.date + " " + x.time) > new Date())).length}</strong> zaplanowanych</div>
                                <div className="col-4"><strong>{patientCard?.reservationsList.filter(x => x.isConfirmed && !x.isCanceled && (new Date(x.date + " " + x.time) < new Date())).length}</strong> odbytych</div>
                                <div className="col-4"><strong>{patientCard?.reservationsList.filter(x => !x.isConfirmed && !x.isCanceled).length}</strong> niepotwierdzonych</div>
                            </div>
                            <hr />
                            <div className="row" style={{ textAlign: "center" }}>
                                <div className="col-6"><strong>{patientCard?.reservationsList.filter(x => x.isCanceled).length}</strong> anulowanych</div>
                                <div className="col-6" style={{ cursor: "pointer" }}>
                                    <strong style={{ fontWeight:"600" }} onClick={() => updateBlock(!accountStatus)}>{accountStatus ? `Zablokowany` : `Niezablokowany`}</strong>
                                </div>
                            </div>
                            <hr />
                            <div className="row" style={{ maxHeight: "200px", overflow: "scroll", overflowX: "hidden" }}>
                                <ReservationTable />
                            </div>
                        </MDBModalBody>
                        <MDBModalFooter className="d-inline-block justify-content-between">
                            {!props.data.IsConfirmed && !props.data.IsCanceled ? (
                                <div className="row">
                                    <div className="col-4">
                                        <MDBBtn block onClick={cancelReservation} color="danger" disabled={new Date().getTime() - new Date(props.data.Date).getTime() > 43200000}>Anuluj</MDBBtn>
                                    </div>
                                    <div className="col-4">
                                        <MDBBtn block onClick={confirmReservation} color="success" disabled={new Date().getTime() - new Date(props.data.Date).getTime() > 21600000}>Potwierdz</MDBBtn>
                                    </div>
                                    <div className="col-4">
                                        <MDBBtn block onClick={() => props.handleModal()} color="primary">Zamknij</MDBBtn>
                                    </div>
                                </div>
                            ) : (
                                <div className="row">
                                    <div className="col-6">
                                        <MDBBtn block onClick={cancelReservation} color="danger" disabled={props.data.IsCanceled || (new Date().getTime() - new Date(props.data.Date).getTime() > 43200000) ? true : false}>{props.data.IsCanceled ? 'Rezerwacja anulowana' : 'Anuluj rezerwacje'}</MDBBtn>
                                    </div>
                                    <div className="col-6">
                                        <MDBBtn block onClick={() => props.handleModal()} color="primary">Zamknij</MDBBtn>
                                    </div>
                                </div>
                            )}
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </>
    );
}
