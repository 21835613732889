import axios, { AxiosResponse } from "axios";
import { MDBBtn, MDBModal, MDBModalDialog, MDBModalContent, MDBModalHeader, MDBModalTitle, MDBModalBody, MDBModalFooter, MDBTypography, MDBCheckbox, MDBInput, MDBValidation, MDBValidationItem, MDBInputGroup, MDBListGroup, MDBListGroupItem, MDBSpinner } from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import { IModalData, IModalProps } from "../../global/Interfaces";
import Constants from "../../utilities/Constants";
import InfoModal from "./InfoModal";

export default function ReservationModal(props: IModalProps) {
    let daysOfWeek = ['', 'Poniedziałek', 'Wtorek', 'Środa', 'Czwartek', 'Piątek', 'Sobota', 'Niedziela'];
    let monthsNames = ['Stycznia', 'Lutego', 'Marca', 'Kwietnia', 'Maja', 'Czerwca', 'Lipca', 'Sierpnia', 'Września', 'Października', 'Listopada', 'Grudnia'];

    const [iModalStatus, setIModalStatus] = useState<boolean>(false);
    const [iModalText, setIModalText] = useState<string>("Wybrany termin nie jest dostępny! \nProsimy wybrać inny.");
    const [buttonStatus, setButtonStatus] = useState<boolean>(true);
    const [selectedDate, setSelectedDate] = useState<IModalData>(props.data);

    useEffect(() => {
        setSelectedDate(props.data);
    }, [props.data]);

    const [formValue, setFormValue] = useState({
        bookerName: "",
        bookerSurname: "",
        name: "",
        surname: "",
        email: "",
        phone: "",
        dateTime: props.data.date + `T` + props.data.time,
        duration: props.data.duration
    });
    
    useEffect(() => {
        setButtonStatus(true);
        const url = Constants.API_URL_GET_VALIDATE;
        axios
            .get(url + `?Date=${props.data.date + `T` + props.data.time}&Duration=${props.data.duration}`)
            .then((response: AxiosResponse) => {

            })
            .catch(function (error: any) {
                props.handleModal();
                props.forceUpdate(100 + Math.random() * (999999));

                setIModalText("Wybrany termin nie jest już dostępny! \nProsimy wybrać inny.");
                setIModalStatus(true);
            });
    }, [props.isActive]);

    const onChange = (e: any) => {
        if (e.target.name == 'phone') {
            setFormValue({ ...formValue, [e.target.name]: e.target.value.replace(/[^0-9 ]/g, '') });
        } else if (e.target.name != 'email') {
            setFormValue({ ...formValue, [e.target.name]: e.target.value.replace(/[^A-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃ]/g, '') });
        } else {
            setFormValue({ ...formValue, [e.target.name]: e.target.value });
        }
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();      
        
        if (formValue.bookerName.length > 2 && formValue.bookerSurname.length > 2 &&
            formValue.name.length > 2 && formValue.surname.length > 2 &&
            formValue.email.length > 4 && formValue.phone.length > 8 &&
            formValue.phone.length < 13 && event.target[6].checked) {
            setButtonStatus(false);

            const dataToSend:any = {
                bookerName: formValue.bookerName,
                bookerSurname: formValue.bookerSurname,
                name: formValue.name,
                surname: formValue.surname,
                email: formValue.email,
                phone: formValue.phone,
                dateTime: selectedDate.date + `T` + selectedDate.time,
                duration: selectedDate.duration
            }

            const url = Constants.API_URL_POST_RESERVATION;
            axios
                .post(url, dataToSend)
                .then((response: AxiosResponse) => {
                    if (selectedDate.duration == 90) {
                        window.gtag('event', 'firstReservationSuccess');
                    } else {
                        window.gtag('event', 'reservationSuccess');
                    }
                    props.forceUpdate(formValue.duration);
                    setButtonStatus(true);
                })
                .catch(function (error: any) {
                    setButtonStatus(true);
                    if (error.response.data.errors.Reservation[0] == "Reservation limit has been reached, two bookings not yet expired.") {
                        setIModalText("Osiągnięto limit rezerwacji! Przed złożeniem kolejnych musisz zrealizować lub anulować 2 poprzednie.");
                    } else if (error.response.data.errors.Reservation[0] == "Account has been blocked.") {
                        setIModalText("Złożenie rezerwacji nie jest w tej chwili możliwe!");
                    } else {
                        setIModalText("Wybrany termin nie jest dostępny! Prosimy wybrać inny.");
                    }
                    setIModalStatus(true);
                });
        }
    }

    return (
        <>
            <InfoModal
                isActive={iModalStatus}
                handleModal={() => setIModalStatus(false)}
                text={iModalText}
            />
            <MDBModal
                className={(iModalStatus ? 'second-modal' : '')}
                show={props.isActive}
                onHide={() => props.handleModal()}
                staticBackdrop={true}
                tabIndex='-1'
            >
                <MDBModalDialog size="lg">
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>
                                Rezerwacja wizyty lekarskiej
                            </MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' disabled={!buttonStatus} onClick={() => props.handleModal()}></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody>
                            <div className="row">
                                <div className="col-md-4">
                                    <MDBTypography tag='h5' className='mb-3'>
                                        Wybrany termin
                                    </MDBTypography>
                                    <div>
                                        <MDBListGroup light>
                                            <MDBListGroupItem className='d-flex justify-content-between align-items-start'>
                                                <div className='ms-2 me-auto'>
                                                    <div className='fw-bold'>{selectedDate.duration == 90 ? `Pierwsza wizyta` : `Kolejna wizyta`}</div>
                                                    {selectedDate.duration == 90 ? `Czas trwania do 120min` : `Czas trwania do 60min`}
                                                </div>
                                            </MDBListGroupItem>
                                            <MDBListGroupItem className='d-flex justify-content-between align-items-start'>
                                                <div className='ms-2 me-auto'>
                                                    <div className='fw-bold'>Wybrany dzień</div>
                                                    {daysOfWeek[new Date(selectedDate.date).getDay()] + ` ` + new Date(selectedDate.date).getDate()}
                                                    {` ` + monthsNames[new Date(selectedDate.date).getMonth()] + ` ` + new Date(selectedDate.date).getFullYear()}
                                                </div>
                                            </MDBListGroupItem>
                                            <MDBListGroupItem className='d-flex justify-content-between align-items-start'>
                                                <div className='ms-2 me-auto'>
                                                    <div className='fw-bold'>Wybrana godzina</div>
                                                    Od {parseInt(selectedDate.time.slice(0, 2)) + ':' + selectedDate.time.slice(3, 5) + ' '}
                                                    do {parseInt(selectedDate.time.slice(0, 2)) + (selectedDate.duration == 90 ? 2 : 1) + ':' + selectedDate.time.slice(3, 5)}
                                                </div>
                                            </MDBListGroupItem>
                                        </MDBListGroup>
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <MDBTypography tag='h5' className='mb-3'>
                                        Dane rezerwacji
                                    </MDBTypography>
                                    <div>
                                        <MDBValidation id='reservationForm' onSubmit={handleSubmit}>
                                            <div className="row mb-4">
                                                <MDBValidationItem className='col' feedback style={{ width: "50%" }}>
                                                    <MDBInput
                                                        style={{ marginBottom: 0 }}
                                                        value={formValue.bookerName}
                                                        type='text'
                                                        minLength={3}
                                                        maxLength={20}
                                                        name='bookerName'
                                                        onChange={onChange}
                                                        id='bookerNameForm'
                                                        required
                                                        label='Imię'
                                                    />
                                                </MDBValidationItem>
                                                <MDBValidationItem className='col' feedback style={{ width: "50%" }}>
                                                    <MDBInput
                                                        style={{ marginBottom: 0 }}
                                                        value={formValue.bookerSurname}
                                                        type='text'
                                                        minLength={3}
                                                        maxLength={20}
                                                        name='bookerSurname'
                                                        onChange={onChange}
                                                        id='bookerSurnameForm'
                                                        required
                                                        label='Nazwisko'
                                                    />
                                                </MDBValidationItem>
                                            </div>
                                            <div className="row mb-4">
                                                <MDBValidationItem className='col' feedback style={{ width: "50%" }}>
                                                    <MDBInput
                                                        style={{ marginBottom: 0 }}
                                                        value={formValue.name}
                                                        type='text'
                                                        minLength={3}
                                                        maxLength={20}
                                                        name='name'
                                                        onChange={onChange}
                                                        id='nameForm'
                                                        required
                                                        label='Imię pacjenta'
                                                    />
                                                </MDBValidationItem>
                                                <MDBValidationItem className='col' feedback style={{ width: "50%" }}>
                                                    <MDBInput
                                                        style={{ marginBottom: 0 }}
                                                        value={formValue.surname}
                                                        type='text'
                                                        minLength={3}
                                                        maxLength={20}
                                                        name='surname'
                                                        onChange={onChange}
                                                        id='surnameForm'
                                                        required
                                                        label='Nazwisko pacjenta'
                                                    />
                                                </MDBValidationItem>
                                            </div>
                                            <div className="row mb-4">
                                                <MDBInputGroup noWrap textBefore='@'>
                                                    <MDBValidationItem feedback style={{ width: "100%" }}>
                                                        <MDBInput
                                                            style={{ marginBottom: 0 }}
                                                            value={formValue.email}
                                                            name='email'
                                                            onChange={onChange}
                                                            id='emailForm'
                                                            type='email'
                                                            required
                                                            label='Adres email'
                                                        />
                                                    </MDBValidationItem>
                                                </MDBInputGroup>
                                            </div>
                                            <div className="row mb-4">
                                                <MDBInputGroup noWrap textBefore='+48'>
                                                    <MDBValidationItem feedback style={{ width: "100%" }}>
                                                        <MDBInput
                                                            style={{ marginBottom: 0 }}
                                                            value={formValue.phone}
                                                            name='phone'
                                                            onChange={onChange}
                                                            id='phoneForm'
                                                            pattern="[0-9 ]{9,11}"
                                                            minLength={9}
                                                            required
                                                            type='tel'
                                                            label='Numer telefonu'
                                                        />
                                                    </MDBValidationItem>
                                                </MDBInputGroup>
                                            </div>
                                            <div className="row mb-2 pb-1">
                                                <MDBValidationItem invalid feedback='*Zgoda jest wymagana do złożenia rezerwacji.'>
                                                    <MDBCheckbox
                                                        label='Wyrażam zgodę na przetwarzanie moich danych osobowych dla potrzeb niezbędnych do realizacji rezerwacji online.'
                                                        id='checkboxForm'
                                                        name="checkbox"
                                                        required
                                                    />
                                                </MDBValidationItem>
                                            </div>
                                        </MDBValidation>
                                    </div>
                                </div>
                            </div>
                        </MDBModalBody>
                        <MDBModalFooter className="d-inline-block justify-content-between">
                            <div className="row">
                                <div className="col-4">
                                    <MDBBtn color='primary' onClick={() => props.handleModal()} disabled={!buttonStatus}>
                                        <span className="d-md-none">Anuluj</span>
                                        <span className="d-none d-md-block">Wybierz inny termin</span>
                                    </MDBBtn>
                                </div>
                                <div className="col-8">
                                    {buttonStatus ? (
                                        <MDBBtn block form="reservationForm" color="success">Rezerwuj</MDBBtn>
                                    ) : (
                                        <MDBBtn block form="reservationForm" disabled color="success">
                                            <MDBSpinner size='sm' role='status' tag='span' className='me-2' />Rezerwuj
                                        </MDBBtn>
                                    )}
                                </div>
                            </div>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </>
    );
}
